<template>
  <div class="word">
    <van-divider />
    <van-form @submit="onSubmit" class="wordForm">
      <van-cell-group inset class="bgCell">
        <van-field :border='false' readonly name="picker"
          right-icon="arrow-down" v-model="wordForm.promotionName" label="媒体名称"
          placeholder="点击选择媒体" @click="setColumns('platform')"
          :rules="[{ required:true, message: '请选择媒体名称' }]" />
        <van-field :border='false' @input="wordForm.kocPlatformId = ''"
          v-model="wordForm.platformCode" center clearable label="媒体账号"
          placeholder="点击添加账号" :rules="[{ required:true, message: '请添加媒体账号' }]">
          <template #button>
            <van-icon @click="setColumns('kocId')" name="arrow-down"
              class='xiala' />
          </template>
        </van-field>
      </van-cell-group>
      <van-cell-group inset>
        <van-field name="radio" label="单选框">
          <template #input>
            <van-radio-group v-model="wordForm.promotionType"
              direction="horizontal">
              <van-radio checked-color="#fe8154" :name="20">图片</van-radio>
              <van-radio checked-color="#fe8154" :name="10">视频</van-radio>
              <van-radio checked-color="#fe8154" :name="30">其他</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </van-cell-group>
      <van-cell-group inset class="bgCell">
        <van-field :border='false' v-model="wordForm.promotionUrl" label="作品链接"
          :placeholder="workLink"
          :rules="[{ validator:validatorUrl, message: '请填写正确的作品链接' }]" />
        <van-field readonly :border='false' name="calendar"
          v-model="wordForm.promotionDate" label="发布日期" placeholder="点击选择日期"
          @click="showCalendar = true"
          :rules="[{  required: true, message: '请选择发布日期' }]" />
      </van-cell-group>
      <van-button block native-type="submit" class="submitBtn" :loading="loading">
        提交
      </van-button>
    </van-form>
  </div>
  <van-popup v-model:show="showPicker" position="bottom">
    <van-picker show-toolbar :columns="columns" @confirm="onConfirm"
      @cancel="showPicker = false" />
  </van-popup>
  <van-calendar v-model:show="showCalendar" :minDate='minDate'
    :max-date="maxDate" :default-date='new Date(wordForm.promotionDate)'
    @confirm="setDate" />
</template>

<script>
import { Toast } from 'vant'
import { getPromotionPlatform } from '@/api/public'
import { addZhihuKeywords } from '@/api/extension.js'
export default {
  name: 'applyWord',
  data() {
    return {
      wordForm: {
        keywordsId: '',
        promotionName: '',
        //推广平台ID（推广账号ID为空时必填）
        promotionPlatformId: '',
        kocPlatformId: '',
        platformCode: '',
        promotionType: 20,
        promotionUrl: '',
        promotionDate: ''
      },
      // 平台列表
      columns: [],
      platformList: [],
      kocList: [],
      showPicker: false,
      showCalendar: false,
      pickerTitle: '',
      maxDate: new Date(),
      worksInfoLink: '',
      loading: false,
    }
  },
  computed: {
    // 设置发布日期范围
    minDate() {
      let currentDate = new Date()
      currentDate.setDate(currentDate.getDate() - 7)
      return currentDate
    },
    // 设置作品链placeholder
    workLink() {
      let name = this.wordForm.promotionName
      if (!name || name == 'KOC其它') {
        return '例：https:// 或 http://'
      } else {
        return name.slice(3) + '作品，例：https://'
      }
    }
  },
  async mounted() {
    this.wordForm.keywordsId = this.$route.query.id
    this.getPromotionPlatform()
  },
  methods: {
    // 获取下拉列表
    async getPromotionPlatform() {
      let list = await getPromotionPlatform({ isZhihu: 20, status: 10 })
      this.platformList = list.map(item => {
        return {
          id: item.id,
          text: item.zhihuName,
          worksInfoLink: item.worksInfoLink,
          childList: item.kocPromotion
        }
      })
      this.columns = JSON.parse(JSON.stringify(this.platformList))
      if (this.$route.query.type == 'edit') {
        // 设置媒体账号列表
        let pValue = this.platformList.find(platform => {
          if (this.wordForm.promotionPlatformId == platform.id) {
            return platform
          }
        })
        this.kocList = pValue.childList.map(item => {
          return {
            id: item.id,
            text: item.platform_code
          }
        })
      }
    },
    // 设置下拉菜单
    setColumns(title) {
      this.showPicker = true
      if (title == 'platform') {
        this.columns = JSON.parse(JSON.stringify(this.platformList))
      } else {
        this.columns = JSON.parse(JSON.stringify(this.kocList))
      }
      this.pickerTitle = title
    },
    onConfirm(value) {
      this[this.pickerTitle] = value
      if (this.pickerTitle == 'platform') {
        this.worksInfoLink = value.worksInfoLink
        // 判断是否选新的媒体了，换媒体选项就重置媒体账号及作品链接
        if (this.wordForm.promotionPlatformId != value.id) {
          this.wordForm.kocPlatformId = ''
          this.wordForm.platformCode = ''
          this.wordForm.promotionUrl = ''
        }
        this.wordForm.promotionName = value.text
        this.wordForm.promotionPlatformId = value.id
        this.kocList = value.childList.map(item => {
          return {
            id: item.platform_code,
            text: item.platform_code
          }
        })
      } else {
        this.wordForm.kocPlatformId = value.id
        this.wordForm.platformCode = value.text
      }
      this.showPicker = false
    },
    setDate(date) {
      this.wordForm.promotionDate = `${date.getFullYear()}/${
        date.getMonth() + 1
      }/${date.getDate()}`
      this.showCalendar = false
    },
    async onSubmit() {
      // kocPlatformId不存在就说明是输入的媒体账号而不是选择的
      // let form = JSON.parse(JSON.stringify(this.wordForm))
      // if (!form.kocPlatformId) {
      //   form.kocPlatformCode = form.platformCode
      // }
      // 在不破坏原有基础上 创建新的接口 需要的数据
      const { kocPlatformId, platformCode, promotionPlatformId, platformName, ...form } = this.wordForm
      this.loading = true
      await addZhihuKeywords({
        platformId: promotionPlatformId,
        platformCode: kocPlatformId || platformCode,
        ...form
      })
      this.loading = false
      Toast('提交成功')
      setTimeout(() => {
        this.$router.back(-1)
      }, 500)
    },
    validatorUrl(text) {
      let val = text.trim()
      let strRegexp = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
      if (!strRegexp.test(val)) {
        return false
      }
      let REarr = val.match(strRegexp)
      let url = REarr.find(item => {
        if (strRegexp.test(item)) {
          return item
        }
      })
      if (
        this.worksInfoLink &&
        !url.toLowerCase().includes(this.worksInfoLink)
      ) {
        return false
      } else {
        this.wordForm.promotionUrl = url
        return true
      }
    }
  }
}
</script>

<style lang='less' scoped>
.word {
  padding: 30px;
  min-height: 100%;
  background: white;
  .word-title {
    font-size: 32px;
  }
  .wordForm {
    .van-cell {
      padding-left: 0px;
      padding-right: 0px;
      /deep/ .van-cell__title {
        width: 140px;
        font-size: 30px !important;
        font-weight: bold !important;
        margin: 0px;
      }
    }
    .bgCell {
      /deep/ .van-cell__value {
        background: #f3f3f3;
        padding: 5px 20px;
        border-radius: 10px;
      }
    }
  }
  .xiala {
    padding-left: 30px;
    border-left: 2px solid #9c9cac;
    font-size: var(--van-field-icon-size);
  }
  .submitBtn {
    margin: 80px auto;
    width: 360px;
    height: 72px;
    font-size: 34px;
    background: #ff855d;
    color: white;
  }
}
</style>